import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c7ee1a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-layout"
};
const _hoisted_2 = {
  class: "slot-container"
};
const _hoisted_3 = {
  class: "slot-main"
};
import MyMenu from '@/components/menu';
import MyHeader from '../header';
import Breadcrumb from '../breadcrumb';
import Banner from '../banner';
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_header = _resolveComponent("el-header");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(() => [_createVNode(_component_el_header, {
          class: "header",
          height: "64px"
        }, {
          default: _withCtx(() => [_createVNode(_unref(MyHeader))]),
          _: 1
        }), _createVNode(_component_el_container, null, {
          default: _withCtx(() => [_createVNode(_component_el_aside, {
            width: "200px",
            class: "aside"
          }, {
            default: _withCtx(() => [_createVNode(_unref(MyMenu))]),
            _: 1
          }), _createVNode(_component_el_container, null, {
            default: _withCtx(() => [_createVNode(_component_el_main, {
              class: "main"
            }, {
              default: _withCtx(() => [_createVNode(_unref(Breadcrumb)), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(Banner), {
                class: "banner-wrapper"
              }), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])])]),
              _: 3
            })]),
            _: 3
          })]),
          _: 3
        })]),
        _: 3
      })]);
    };
  }
};