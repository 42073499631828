import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21035836"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "banner-wrapper"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "desc ws_mt20"
};
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const $route = useRoute();
    const mateInfo = ref({});
    const matchMate = () => {
      const {
        meta
      } = $route;
      mateInfo.value = meta;
    };
    watch($route, () => {
      matchMate();
    }, {
      deep: true,
      immediate: true
    });
    return (_ctx, _cache) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(mateInfo.value.title), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(mateInfo.value.banner), 1)], 512)), [[_vShow, mateInfo.value.banner]]);
    };
  }
};