export const CODE_ENUM = {
  SUCCESS: 0,
  // 权限
  AUTHORIZATION_NOT: 401
};

export const PAGE_SIZES = [10, 20, 50, 100, 200, 300, 400];

// 服务
export const SUFFIX = {
  TIANSHU: '/api-tianshu',
  USER: '/api-user',
  DCOP: '/api-dcop',
  DSTB: '/api-dstb'
};

// 套餐-发布状态
export const PACKAGE_PUBLISH_STATUS_CODE = {
  PACKAGE_PUBLISH: 'PACKAGE_PUBLISH', //发布
  PACKAGE_CANCLE: 'PACKAGE_CANCLE' //撤销发布
};

// 套餐-宽带类型
export const PACKAGE_BROADBAND_TYPE_CODE = {
  PACKAGE_BROADBAND_200: 'PACKAGE_BROADBAND_200' //带宽类型200bps
};

// 套餐-计费模式
export const PACKAGE_CHARG_MODE_CODE = {
  PACKAGE_CHARG_MONTH: 'PACKAGE_CHARG_MONTH' //模式包月
};

// 分销商等级
export const DISTRIBUTOR_LEVEL_CODE = {
  LEVEL1: 'LEVEL1', // 一级
  LEVEL2: 'LEVEL2', // 二级
  LEVEL3: 'LEVEL3' // 三级
};

// 设备状态
export const DEVICE_STATUS_CODE = {
  NO_ACTIVATE: 'NO_ACTIVATE',
  ACTIVATED: 'ACTIVATED'
};

// 订单状态
export const ORDER_STATUS_CODE = {
  ORDER_UNPAY: 'ORDER_UNPAY', //付款中
  ORDER_PAY_FINISH: 'ORDER_PAY_FINISH', //付款完成
  ORDER_CANCLE: 'ORDER_CANCLE' //取消
};

// 结算状态
export const SETTLEMENT_STATUS_CODE = {
  UNSETTLED: 'UNSETTLED', //未结算
  SETTLED: 'SETTLED' //已结算
};
