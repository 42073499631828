import { resolvePost, resolveGet } from 'common/request';
import { SUFFIX } from 'common';

// 分页查询
export const queryMemberPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/member/page',
    params
  });
};

// 查询会员详情
export const queryMemberDetail = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/member/detail',
    params
  });
};
