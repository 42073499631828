import { resolvePost } from 'common/request';
import { SUFFIX } from 'common';

// 登陆
export const oauthToken = (params) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    context: true
  });
};
// 退出登陆
export const oauthRemoveToken = (params) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/remove/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  });
};
