import { resolvePost, resolveGet } from 'common/request';
import { SUFFIX } from 'common';

// 分页查询
export const queryProductPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/product/page',
    params
  });
};

// 添加产品
export const addProductForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/product/add',
    params
  });
};

// 编辑产品
export const updateProductForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/product/edit',
    params
  });
};

// 删除产品
export const deleteProductForm = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/product/delete',
    params
  });
};

// 查询产品详情
export const queryProductInfo = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/product/detail',
    params
  });
};
