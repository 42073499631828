import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app"
};
import { RouterView } from 'vue-router';
import Layout from 'common/components/layout';
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const $route = useRoute();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!_unref($route).meta.layout ? (_openBlock(), _createBlock(_unref(RouterView), {
        key: 0
      })) : (_openBlock(), _createBlock(_unref(Layout), {
        key: 1
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_unref(RouterView), {
          key: _unref($route).fullPath
        }))]),
        _: 1
      }))]);
    };
  }
};