import { resolvePost, resolveGet } from 'common/request';
import { SUFFIX } from 'common';

// 分页查询
export const queryDevicePage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/device/page',
    params
  });
};

// 下拉列表-分销商
export const queryDeviceDistributorTreeList = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/device/treeList/distributor',
    params
  });
};
