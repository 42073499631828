import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: true
    },
    redirect: '/product/product-list'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: false
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      layout: true,
      title: '产品管理'
    },
    redirect: '/product/product-list',
    children: [
      {
        path: 'product-list',
        name: 'product-list',
        meta: {
          title: '产品列表',
          banner: '可以添加进行销售的产品(模组)，可编辑和删除产品。'
        },
        component: () => import('@/views/product/index.vue')
      }
    ]
  },
  {
    path: '/member',
    name: 'member',
    meta: {
      layout: true,
      title: '会员管理'
    },
    redirect: '/member/member-list',
    children: [
      {
        path: 'member-list',
        name: 'member-list',
        meta: {
          title: '会员列表',
          banner: '可以查询注册的会员，包括注册设备和绑定设备的数量。'
        },
        component: () => import('@/views/member/index.vue'),
        redirect: '/member/member-list',
        children: [
          {
            path: '',
            name: 'member-list-index',
            meta: {
              title: '会员列表',
              banner: '可以查询注册的会员，包括注册设备和绑定设备的数量。'
            },
            component: () => import('@/views/member/list/index.vue')
          },
          {
            path: 'member-list-detail',
            name: 'member-list-detail',
            meta: {
              title: '会员详情',
              banner:
                '可以查询会员的账户信息和绑定的设备，包括设备关联的套餐信息。'
            },
            component: () => import('@/views/member/detail/index.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/distribution',
    name: 'distribution',
    meta: {
      layout: true,
      title: '分销管理'
    },
    redirect: '/distribution/distributor-list',
    children: [
      {
        path: 'distributor-list',
        name: 'distributor-list',
        meta: {
          title: '分销商管理',
          banner: '可以查询、添加、修改和删除分销商。'
        },
        redirect: '/distribution/distributor-list',
        component: () => import('@/views/distribution/distributor/index.vue'),
        children: [
          {
            path: '',
            name: 'distributor-list-index',
            meta: {
              title: '分销商列表',
              banner: '可以查询、添加、修改和删除分销商。'
            },
            component: () =>
              import('@/views/distribution/distributor/list/index.vue')
          },
          {
            path: 'distributor-detail',
            name: 'distributor-detail',
            meta: {
              title: '分销商详情',
              banner:
                '可以查询分销商的账户详情，包括基础信息，认证资料和结算账户信息。'
            },
            component: () =>
              import('@/views/distribution/distributor/detail/index.vue')
          }
        ]
      },
      {
        path: 'distribution-list',
        name: 'distribution-list',
        meta: {
          title: '分销明细',
          banner:
            '可以查询和分销商关联的订单信息，包括订单套餐名称、金额、创建时间、分润比例和结算状态。'
        },
        component: () =>
          import('@/views/distribution/distribution-list/index.vue')
      },
      {
        path: 'order-list',
        name: 'order-list',
        meta: {
          title: '订单管理',
          banner: '可以查询所有订单信息，包括订单对应的套餐、会员和订单状态。'
        },
        component: () => import('@/views/distribution/order-list/index.vue')
      },
      {
        path: 'count-list',
        name: 'count-list',
        meta: {
          title: '分销统计',
          banner:
            '可以查询分销商累计已结算和未结算的金额，未结算的金额可以下钻查询分润明细。'
        },
        component: () => import('@/views/distribution/count-list/index.vue')
      }
    ]
  },
  {
    path: '/device',
    name: 'device',
    meta: {
      layout: true,
      title: '设备管理'
    },
    redirect: '/device/device-list',
    children: [
      {
        path: 'device-list',
        name: 'device-list',
        meta: {
          title: '设备列表',
          banner:
            '可查询所有分销商添加的设备，包括设备关联的分销商、会员和套餐信息。'
        },
        component: () => import('@/views/device/index.vue')
      }
    ]
  },
  {
    path: '/package',
    name: 'package',
    meta: {
      layout: true,
      title: '套餐管理'
    },
    redirect: '/package/package-list',
    children: [
      {
        path: 'package-list',
        name: 'package-list',
        meta: {
          title: '套餐列表',
          banner: '可以添加、发布、编辑和删除套餐。'
        },
        component: () => import('@/views/package/index.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
