import { resolvePost, resolveGet } from 'common/request';
import { SUFFIX } from 'common';

// 分页查询
export const queryDistributorPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/page',
    params
  });
};

// 添加分销商
export const addDistributorForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/add',
    params
  });
};

// 编辑分销商
export const updateDistributorForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/edit',
    params
  });
};

// 删除分销商
export const deleteDistributorForm = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/delete',
    params
  });
};

// 查询分销商详情
export const queryDistributorInfo = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/get',
    params
  });
};

// 查询订单分页列表
export const queryTradeorderPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/tradeorder/page',
    params
  });
};

// 分润明细-分页
export const queryDistributorProfitPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/profit/detail/page',
    params
  });
};

// 分润统计-分页
export const queryDistributorProfitTotalPage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/distributor/profit/total/page',
    params
  });
};
