import md5 from 'md5';
import sha384 from 'crypto-js/sha384';
import moment from 'moment';
import { isEmpty } from 'lodash';

const reg = /\B(?=(\d{3})+(?!\d))/g;

// 将数字中逗号分隔
export const transformNumber = (number = 0) => {
  if (number === 0) {
    return 0;
  }
  return number.toFixed(2).toString().replace(reg, ',');
};

// 明文加密，md5->sh384
export const encode = (input) => {
  return sha384(md5(input)).toString();
};

// 验证码加密，sh384
export const sha = (input) => {
  return sha384(input).toString();
};

// 获取表单时间
export const formatFormTime = (data, keyList) => {
  if (isEmpty(data)) {
    return;
  }
  const [start, end] = data;
  const [startKey, endKey] = keyList;
  return {
    [startKey]: moment(start).valueOf(),
    [endKey]: moment(end).valueOf()
  };
};

// 下载文件
export const downLoadFile = ({ blob, fileName }) => {
  const objectUrl = URL.createObjectURL(blob); //创建下载的链接
  const a = document.createElement('a');
  a.href = objectUrl;
  a.download = fileName; //设置文件名
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  );
  window.URL.revokeObjectURL(blob); //释放bolb对象
};
