import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e856847"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header-wrapper"
};
const _hoisted_2 = {
  class: "right"
};
const _hoisted_3 = {
  class: "user"
};
import { ref, onMounted, computed } from 'vue';
import defaultUserIcon from '@/assets/icons/icon-user-default.png';
import { oauthRemoveToken } from '@/api';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const dropdownList = [{
      command: 'signOut',
      label: '退出'
    }];
    const handleCommand = async command => {
      oauthRemoveToken({
        token: localStorage.getItem('WX_Authorization')
      }).finally(() => {
        router.push('/login');
        localStorage.removeItem('WX_Authorization');
        localStorage.removeItem('WX_AuthorizationUser');
      });
    };
    const userName = ref('');
    onMounted(() => {
      userName.value = localStorage.getItem('WX_AuthorizationUser') || '';
    });
    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_dropdown, {
        onCommand: handleCommand
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(dropdownList, (item, index) => {
            return _createVNode(_component_el_dropdown_item, {
              key: index,
              command: item.command
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["command"]);
          }), 64))]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_avatar, {
          size: 25,
          src: _unref(defaultUserIcon)
        }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(userName.value), 1)])]),
        _: 1
      })])]);
    };
  }
};