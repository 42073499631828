import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
import { ref, watch } from 'vue';
import { ArrowRight } from '@element-plus/icons-vue';
import { useRoute } from 'vue-router';
import { routes } from '@/router';
export default {
  __name: 'index',
  setup(__props) {
    const $route = useRoute();
    const getPaths = () => {
      const regex = /[^/]+/g;
      return $route.path.match(regex);
    };
    const getLink = index => {
      const item = mataList.value[index];
      return index < mataList.value.length - 1 ? {
        name: item.name
      } : undefined;
    };
    const getRoutesList = (list, pathList) => {
      if (!pathList) {
        return [getItem(list, 'home')];
      } else if (pathList.length === 1) {
        return [getItem(list, pathList[0])];
      } else {
        const result = [];
        let current = list;
        pathList.forEach(path => {
          const routeItem = getItem(current, path) || {};
          result.push({
            name: routeItem.name,
            meta: routeItem.meta
          });
          current = routeItem.children || [];
        });
        return result;
      }
    };
    const getItem = (list, path) => {
      const result = list.filter(item => item.name === path);
      return result[0];
    };
    const mataList = ref([]);
    watch(() => $route, () => {
      const result = getRoutesList(routes, getPaths());
      mataList.value = result;
    }, {
      deep: true,
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
      const _component_el_affix = _resolveComponent("el-affix");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_affix, {
        offset: 64
      }, {
        default: _withCtx(() => [_createVNode(_component_el_breadcrumb, {
          "separator-icon": _unref(ArrowRight),
          class: "breadcrumb-wapper fixed"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mataList.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: index,
              to: getLink(index)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.meta.title), 1)]),
              _: 2
            }, 1032, ["to"]);
          }), 128))]),
          _: 1
        }, 8, ["separator-icon"])]),
        _: 1
      })]);
    };
  }
};