import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7bf8d2fd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "menu-wrapper"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
import { onMounted, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import ICON_1 from '@/assets/img/menu/icon-1.svg';
import ICON_1_A from '@/assets/img/menu/icon-1-a.svg';
import ICON_2 from '@/assets/img/menu/icon-2.svg';
import ICON_2_A from '@/assets/img/menu/icon-2-a.svg';
import ICON_3 from '@/assets/img/menu/icon-3.svg';
import ICON_3_A from '@/assets/img/menu/icon-3-a.svg';
import ICON_4 from '@/assets/img/menu/icon-4.svg';
import ICON_4_A from '@/assets/img/menu/icon-4-a.svg';
import ICON_5 from '@/assets/img/menu/icon-5.svg';
import ICON_5_A from '@/assets/img/menu/icon-5-a.svg';
export default {
  __name: 'index',
  setup(__props) {
    const menuData = ref([{
      label: '产品管理',
      icon: ICON_1,
      active: ICON_1_A,
      router: '/product',
      children: [{
        label: '产品列表',
        router: '/product/product-list'
      }]
    }, {
      label: '会员管理',
      icon: ICON_2,
      active: ICON_2_A,
      router: '/member',
      children: [{
        label: '会员列表',
        router: '/member/member-list'
      }]
    }, {
      label: '分销管理',
      icon: ICON_3,
      active: ICON_3_A,
      router: '/distribution',
      children: [{
        label: '分销商管理',
        router: '/distribution/distributor-list'
      }, {
        label: '订单管理',
        router: '/distribution/order-list'
      }, {
        label: '分销明细',
        router: '/distribution/distribution-list'
      }, {
        label: '分销统计',
        router: '/distribution/count-list'
      }]
    }, {
      label: '设备管理',
      icon: ICON_4,
      active: ICON_4_A,
      router: '/device',
      children: [{
        label: '设备列表',
        router: '/device/device-list'
      }]
    }, {
      label: '套餐管理',
      icon: ICON_5,
      active: ICON_5_A,
      router: '/package',
      children: [{
        label: '套餐列表',
        router: '/package/package-list'
      }]
    }]);
    const defaultActive = ref('');
    const $route = useRoute();
    watch($route, () => {
      matchPath();
    });
    onMounted(() => {
      matchPath();
    });
    const matchPath = () => {
      defaultActive.value = $route.path;
    };
    const select = router => {
      defaultActive.value = router;
    };
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
        "active-text-color": "#186DF5",
        "default-active": defaultActive.value,
        "text-color": "#4E5969",
        router: "",
        onSelect: select
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuData.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [!item.children ? (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 0,
            index: item.router
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: defaultActive.value === item.router ? item.active : item.icon,
              alt: ""
            }, null, 8, _hoisted_2), _createElementVNode("span", null, _toDisplayString(item.label), 1)]),
            _: 2
          }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: 1,
            index: item.router
          }, {
            title: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: ""
            }, null, 8, _hoisted_3), _createElementVNode("span", null, _toDisplayString(item.label), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (vv, ii) => {
              return _openBlock(), _createBlock(_component_el_menu_item, {
                index: vv.router,
                key: ii
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(vv.label), 1)]),
                _: 2
              }, 1032, ["index"]);
            }), 128))]),
            _: 2
          }, 1032, ["index"]))]);
        }), 128))]),
        _: 1
      }, 8, ["default-active"])]);
    };
  }
};