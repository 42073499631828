import { resolvePost, resolveGet } from 'common/request';
import { SUFFIX } from 'common';

// 添加套餐
export const addPackageForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/add',
    params
  });
};

// 修改套餐
export const updatePackageForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/edit',
    params
  });
};

// 查询套餐分页列表
export const queryPackagePage = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/page',
    params
  });
};

// 删除套餐
export const deletePackageForm = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/delete',
    params
  });
};

// 查询套餐详情
export const queryPackageInfo = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/detail',
    params
  });
};

// 删除套餐
export const publishPackageForm = (params) => {
  return resolvePost({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/publish',
    params
  });
};

// 套餐列表List
export const queryPackageList = (params) => {
  return resolveGet({
    suffix: SUFFIX.DSTB,
    url: '/manage/package/package/all',
    params
  });
};
